import React from 'react'
import { Link } from 'gatsby'
import { map, truncate, take } from 'lodash'
import { Trans } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import SearchBar from 'components/block/SearchBar'
import routes from '~/routes'
import Extract from './Extract'
import style from './searchresults.module.scss'

export default withLocale(({ query, results = [], locale }) => (
  <div className={ style.main }>
    <SearchBar
      defaultQuery={ query }
    />
    {
      results.length ?
        <div className={ style.meta }>
          <Trans>Resultados de la búsqueda: "<em>{query}</em>"</Trans>
        </div> :
        null
    }
    <div className={ style.results }>
    {
      results.length ?
        map(take(results, 20), article =>
          <div key={ article.title.value } className={ style.result }>
            <h3 className={ style.title }>
              <Link to={ routes.article(article) }>
                <span
                  dangerouslySetInnerHTML={{ __html: article.title.value }}
                />
              </Link>
            </h3>
            <Extract
              className={ style.body }
              match={ article.body.matchedWords[0] }
              content={ article.body.value }
            />
          </div>
        ) :
        <div className={ style.empty }>
          <Trans>No hay resultados para esa búsqueda.</Trans>
        </div>
    }
    </div>
  </div>
))
