import React from 'react'
import { removeMarkdown, removeMarkdownImages, removeMarkdownLinks } from '~/utils/html'
import { findIndex, truncateWithCenter } from '~/utils/string'

const Extract = ({ className, content, match = '' }) => {
  const withoutLinksOrImages = removeMarkdownImages(removeMarkdownLinks(content))
  return (
    <p
      className={ className }
      dangerouslySetInnerHTML={{
        __html: removeMarkdown(
          truncateWithCenter(
            withoutLinksOrImages,
            findIndex(withoutLinksOrImages, `<em>`),
            300
          )
        ),
      }}
    />
  )
}

export default Extract
