import settings from '~/settings'
import api, { authorizedGet } from '~/api'
import transform from './transforms'

export async function search(q, locale, token) {
  try {
    const hits = await api.authorizedGet(
      `${ settings.external.api }/search?q=${ encodeURIComponent(q) }`,
      undefined,
      undefined,
      token
    )
    return transform(hits, locale)
  } catch(e) {
    console.log(e)
  }
}