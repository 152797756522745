import React, { Component } from 'react'
import { Default as Layout } from 'components/layout/Layout'
import Loading from 'components/common/Loading'
import { withLocale } from 'components/common/Locale'
import { withAuth } from '~/components/common/Auth'
import SearchResults from 'components/section/SearchResults'
import { getLocationParam } from '~/utils/misc'
import { search } from '~/service/search'

class SearchPage extends Component {
  state = {
    isSearchComplete: false,
    query: '',
    results: [],
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const query = getLocationParam(nextProps.location, 'q')
    if (query !== prevState.query) {
      return { query }
    }

    return {}
  }

  componentDidMount() {
    const { query } = this.state
    this.handleSearch(query)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.query !== this.state.query) {
      this.handleSearch(this.state.query)
    }
  }

  handleSearch = async (query) => {
    const { locale, accessToken } = this.props
    this.setState({ query, isSearchComplete: false })
    let results
    try {
      results = await search(query, locale, accessToken)
    } catch (e) {
      results = []
    }
    this.setState({ isSearchComplete: true, results })
  }

  render() {
    const { location } = this.props
    const { isSearchComplete, results, query } = this.state

    return (
      <Layout location={ location }>
        {
          isSearchComplete ?
            <SearchResults
              query={ query }
              results={ results }
            /> :
            <Loading isFullScreen />

        }
      </Layout>
    )
  }
}

export default withLocale(withAuth(SearchPage))
