import { get, find, filter, map, mapValues, pick } from 'lodash'
import { firstKey } from '~/utils/misc'

// filter only those of the current language that contain matches
const matchesForLocale = (results, locale) =>
  filter(results, result =>
    find(result._highlightResult.fields, (value, key) =>
      get(value, `${ locale }.matchLevel`, 'none') !== 'none'
    ))

const localizedNode = (node, locale) =>
  mapValues(node, (field = {}) => field[locale] || firstKey(field))

// transform extracting desired values
export default (results, locale) =>
  map(matchesForLocale(results, locale), result => ({
    ...localizedNode(result._highlightResult.fields, locale),
    slug: result.fields.slug[locale],
    node_locale: locale,
  }))